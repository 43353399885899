<header id="page-topbar">
  <div class="navbar-header-Cs">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-sm-dark.png" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img
              src="assets/images/logo-no-background.png"
              alt=""
              height="20"
            />
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-sm-light.png" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="20" />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-24 d-lg-none header-item"
        (click)="toggleMenubar()"
      >
        <i class="ri-menu-2-line align-middle"></i>
      </button>

      <!-- App Search-->
      <form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input
            type="text"
            class="form-control"
            placeholder="{{'HEADER.SEARCH' | translate}}"
          />
          <span class="ri-search-line"></span>
        </div>
      </form>

      <div class="dropdown dropdown-mega d-none d-lg-block ml-2" ngbDropdown>
        <button
          ngbDropdownToggle
          type="button"
          class="btn header-item waves-effect"
          data-toggle="dropdown"
          aria-haspopup="false"
          aria-expanded="false"
        >
          {{ 'HEADER.MEGA_MENU' | translate}}
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-megamenu" ngbDropdownMenu>
          <div class="row">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ 'HEADER.UI.TITLE' | translate}}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.UI.LIGHTBOX' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.UI.RANGE_SLIDER' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.UI.SWEET_ALERT' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.UI.RATING' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.UI.FORMS' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.UI.TABLES' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.UI.CHARTS' | translate}}</a
                      >
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ 'HEADER.APPLICATIONS.TITLE' | translate}}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.APPLICATIONS.ECOMMERCE' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.APPLICATIONS.CALENDAR' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.APPLICATIONS.EMAIL' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.APPLICATIONS.PROJECTS' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.APPLICATIONS.TASKS' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.APPLICATIONS.CONTACTS' | translate}}</a
                      >
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ 'HEADER.EXTRA_PAGES.TITLE' | translate}}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.EXTRA_PAGES.LIGHT_SIDEBAR' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.EXTRA_PAGES.COMPACT_SIDEBAR' |
                        translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.EXTRA_PAGES.HORIZONTAL_LAYOUT' |
                        translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.EXTRA_PAGES.MAINTENANCE' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.EXTRA_PAGES.COMING SOON' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.EXTRA_PAGES.TIMELINE' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.EXTRA_PAGES.FAQ' | translate}}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0">
                    {{ 'HEADER.UI.TITLE' | translate}}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.UI.LIGHTBOX' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.UI.RANGE_SLIDER' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.UI.SWEET_ALERT' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.UI.RATING' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.UI.FORMS' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.UI.TABLES' | translate}}</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >{{ 'HEADER.UI.CHARTS' | translate}}</a
                      >
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img
                      src="assets/images/megamenu-img.png"
                      alt=""
                      class="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ml-2" ngbDropdown>
        <button
          type="button"
          ngbDropdownToggle
          class="btn header-item noti-icon waves-effect"
          id="page-header-search-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="ri-search-line"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
          aria-labelledby="page-header-search-dropdown"
          ngbDropdownMenu
        >
          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="ri-search-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          id="page-header-user-dropdown"
          ngbDropdownToggle
        >
          <img
            *ngIf="flagvalue !== undefined"
            src="{{flagvalue}}"
            alt="Header Language"
            height="16"
          />
          <span class="ml-1">{{countryName}}</span>
          <img
            *ngIf="flagvalue === undefined"
            src="{{valueset}}"
            alt="Header Language"
            height="16"
          />
          <span *ngIf="flagvalue === undefined" class="ml-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
          <a
            href="javascript:void(0);"
            class="dropdown-item notify-item"
            *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)"
            [ngClass]="{'active': cookieValue === item.lang}"
          >
            <img
              src="{{item.flag}}"
              alt="user-image"
              class="mr-1"
              height="12"
            />
            <span class="align-middle">{{item.text}}</span>
          </a>
          <!-- item-->
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ml-1" ngbDropdown>
        <button
          type="button"
          ngbDropdownToggle
          class="btn header-item noti-icon waves-effect"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="ri-apps-2-line"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
          ngbDropdownMenu
        >
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img src="assets/images/brands/github.png" alt="Github" />
                  <span>GitHub</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img
                    src="assets/images/brands/bitbucket.png"
                    alt="bitbucket"
                  />
                  <span>Bitbucket</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img src="assets/images/brands/dribbble.png" alt="dribbble" />
                  <span>Dribbble</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img src="assets/images/brands/dropbox.png" alt="dropbox" />
                  <span>Dropbox</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img
                    src="assets/images/brands/mail_chimp.png"
                    alt="mail_chimp"
                  />
                  <span>Mail Chimp</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img src="assets/images/brands/slack.png" alt="slack" />
                  <span>Slack</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ml-1">
        <button
          type="button"
          class="btn header-item noti-icon waves-effect"
          data-toggle="fullscreen"
          (click)="fullscreen()"
        >
          <i class="ri-fullscreen-line"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          ngbDropdownToggle
          class="btn header-item noti-icon waves-effect"
          id="page-header-notifications-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="ri-notification-3-line"></i>
          <span class="noti-dot"></span>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
          ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown"
        >
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ 'HEADER.NOTIFICATIONS.TITLE' | translate}}
                </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small"
                  >{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a
                >
              </div>
            </div>
          </div>
          <perfect-scrollbar
            style="position: relative; height: 230px;"
            [config]="configData"
          >
            <a href="" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="ri-shopping-cart-line"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i> {{
                      'HEADER.NOTIFICATIONS.FIRST.TIME' | translate}}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <img
                  src="assets/images/users/avatar-3.jpg"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>{{
                      'HEADER.NOTIFICATIONS.SECOND.TIME' | translate}}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-success rounded-circle font-size-16"
                  >
                    <i class="ri-checkbox-circle-line"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>{{
                      'HEADER.NOTIFICATIONS.THIRD.TIME' | translate}}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <img
                  src="assets/images/users/avatar-4.jpg"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>{{
                      'HEADER.NOTIFICATIONS.FOUR.TIME' | translate}}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </perfect-scrollbar>
          <div class="p-2 border-top">
            <a
              class="btn btn-sm btn-link font-size-14 btn-block text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-right-circle mr-1"></i> {{
              'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button
          type="button"
          ngbDropdownToggle
          class="btn header-item waves-effect"
          id="page-header-user-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            class="rounded-circle header-profile-user"
            src="assets/images/users/avatar-2.jpg"
            alt="Header Avatar"
          />
          <span class="d-none d-xl-inline-block ml-1">Kevin</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);"
            ><i class="ri-user-line align-middle mr-1"></i> {{
            'HEADER.LOGIN.PROFILE' | translate}}</a
          >
          <a class="dropdown-item" href="javascript: void(0);"
            ><i class="ri-wallet-2-line align-middle mr-1"></i>{{
            'HEADER.LOGIN.MY_WALLET' | translate}}</a
          >
          <a class="dropdown-item d-block" href="javascript: void(0);"
            ><span class="badge badge-success float-right mt-1">11</span
            ><i class="ri-settings-2-line align-middle mr-1"></i>{{
            'HEADER.LOGIN.SETTINGS' | translate}}
          </a>
          <a class="dropdown-item" href="javascript: void(0);"
            ><i class="ri-lock-unlock-line align-middle mr-1"></i> {{
            'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a
          >
          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item text-danger"
            href="javascript: void(0);"
            (click)="logout()"
            ><i class="ri-shut-down-line align-middle mr-1 text-danger"></i> {{
            'HEADER.LOGIN.LOGOUT' | translate}}</a
          >
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item noti-icon right-bar-toggle waves-effect"
          (click)="onSettingsButtonClicked()"
        >
          <i class="ri-settings-2-line"></i>
        </button>
      </div>
    </div>
  </div>
</header>
