<!-- <app-pagetitle title="{{'Danh sách cửa hàng' | translate}}" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->

<div class="card">
    <div class="card-body">
      <h5>{{'Danh sách cửa hàng' | translate}}</h5>
      <div class="row">
        <!-- <div class="col-3">
                  <div class="form-group">
                      <label for="validationCustom01">{{'Chọn ngày ' | translate}}</label>
                      <div>
                          <ng-template #t let-date let-focused="focused">
                              <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                                  [class.faded]="isHovered(date) || isInside(date)"
                                  (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                  {{ date.day }}
                              </span>
                          </ng-template>
                          <div class="position-relative">
                              <input type="text" class="form-control date" (click)="hidden=!hidden"
                                  name="daterange" autocomplete="off" [(ngModel)]="selected" readonly
                                  placeholder="{{'Ngày/ Tháng/ Năm' | translate}}" />
                              <span class="clearDate" *ngIf="selected" (click)="clearDate()">x</span>
                          </div>
  
                          <ngb-datepicker #dp (select)="onDateSelection($event)" [displayMonths]="2"
                              [dayTemplate]="t" outsideDays="hidden" [hidden]="hidden">
                          </ngb-datepicker>
                      </div>
                  </div>
              </div> -->
        <div class="flex-fill row">
          <!-- <div class="col-4">
                          <div class="form-group">
                              <label for="validationCustom01">{{'PAGE.REPORT.IncidentType' | translate}}</label>
                              <ng-select [(ngModel)]="filter.type_id" [clearable]="false">
                                  <ng-option [value]="-1">{{'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</ng-option>
                                  <ng-option [value]="item.id" *ngFor="let item of listType">
                                      {{item.name}}</ng-option>
                              </ng-select>
                              <select class="form-control" [(ngModel)]="filter.type_id">
                                  <option value="">{{'PAGE.REPORT.SelectIncidentType' | translate}}</option>
                                  <option [ngValue]="item.id" *ngFor="let item of listType">{{item.name}}</option>
                              </select>
                          </div>
                      </div> -->
          <!-- <div class="col-4">
                          <div class="form-group">
                              <label for="validationCustom01">{{'PAGE.REPORT.Location' | translate}}</label>
                              <ng-select [(ngModel)]="filter.location_id" [clearable]="false">
                                  <ng-option [value]="-1">{{'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</ng-option>
                                  <ng-option [value]="item.id" *ngFor="let item of location$|async">
                                      {{item.name}}</ng-option>
                                  <ng-option [value]="0"> {{'PAGE.REPORT.other' | translate}}</ng-option>
                              </ng-select>
                          </div>
                      </div> -->
          <div class="col-4">
            <div class="form-group">
              <label for="validationCustom01">{{'Cửa hàng' | translate}}</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="filter.name"
                placeholder="{{'Nhập tên cửa hàng' | translate}}"
              />
            </div>
          </div>
          <div class="col-4">
            <div style="min-width: 200px; padding-left: 15px;">
              <div class="form-group">
                <label for="validationCustom01" class="d-block">&nbsp;</label>
                <button class="btn btn-primary btnall" (click)="submitFilter()">
                  {{'PAGE.REPORT.Search' | translate}}
                </button>
                <button
                  class="btn btn-dark ml-2"
                  *ngIf="searching"
                  (click)="clearFilter()"
                >
                  {{'FORM.Clear' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end d-flex">
        <!--  -->
        <button class="btn btn-success mb-2" (click)="openModal(content)">
          <i class="mdi mdi-plus mr-2"></i> {{'TABLE.AddMore' | translate}}
        </button>
        <!-- <button class="btn btn-info mb-2" (click)="openModalLink(contentLink)">
                  <i class="mdi mdi-plus mr-2"></i> {{'Liên kết' | translate}}
              </button> -->
      </div>
      <div class="row mb-md-2"></div>
      <div class="table-responsive">
        <table
          id="basic-datatable"
          class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
        >
          <thead>
            <tr class="text-center bg-table">
              <th>{{'#' | translate}}</th>
              <th>{{'Mã cửa hàng' | translate}}</th>
              <th>{{'Tên cửa hàng' | translate}}</th>
              <!-- <th>{{'Số điện thoại' | translate}}</th>                       -->
              <!-- <th>{{'Mã model' | translate}}</th> -->
              <th>{{'Trưởng nhóm' | translate}}</th>
              <!-- <th>{{'Phiên bản' | translate}}</th> -->
              <th>{{'Ngày tạo' | translate}}</th>
              <!-- <th>{{'Ngày cập nhật' | translate}}</th> -->
              <th>{{'Quản lý' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" *ngFor="let data of listData;let i=index">
              <td>
                <a>{{pageSize*(pageIndex-1) +i+1}}</a>
              </td>
  
              <!-- <td *ngIf="role ==='admin'">{{data.phone}}</td> -->
              <!-- <td *ngIf="role ==='admin'">{{data.workspace_name}}</td> -->
              <td><a>{{data.code}}</a></td>
              <td><a>{{data.name}}</a></td>
              <td><a>{{data.leader}}</a></td>
  
              <!-- <td>
                              {{data.version}}
                          </td> -->
              <td>
                <a>{{data.created_at | date:'dd/MM/yyyy HH:mm'}}</a>
              </td>
              <!-- <td>{{data.updated_at | date:'dd/MM/yyyy HH:mm'}}</td> -->
              <td class="py-2 table-column text-center">
                <div class="" style="gap: 5px;">
                  <!-- <a
                    class="font-size-18 text-success"
                    ngbTooltip="{{'Chi tiết' | translate}}"
                    
                  >
                    <i class="mdi mdi-eye"></i>
                  </a> -->
                  <!-- <a class="font-size-18" href="javascript: void(0);" ngbTooltip="{{'FORM.Edit' | translate}}" placement="bottom-left" (click)="onEdit(data, content)">
                                      <i class="mdi mdi-pen"></i>
                                      {{'FORM.Edit' | translate}}
                                  </a>
                                  <a class="font-size-18 text-danger" ngbTooltip="{{'FORM.Delete' | translate}}" placement="bottom-left" (click)="onDelete(data.id)">
                                      <i class="mdi mdi-delete"></i>
                                      {{'FORM.Delete' | translate}}
                                  </a> -->
                  <!-- <a class="font-size-18 text-body" ngbTooltip="{{'FORM.KeywordManager' | translate}}" placement="left" (click)="onOpenKeywordModal(contentKeyword, data)">
                                      <i class="mdi mdi-cog"></i>
                                      {{'FORM.KeywordManager' | translate}}
                                  </a> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row justify-content-md-between align-items-md-center mt-2">
        <div class="col-sm-12 col-md-5">
          <div
            class="dataTables_info mb-2"
            id="tickets-table_info"
            role="status"
            aria-live="polite"
          >
            {{'TABLE.Showing' | translate}} {{pageSize*pageIndex - pageSize + 1}}
            {{'TABLE.to' | translate}} {{pageSize*pageIndex}} {{'TABLE.of' |
            translate}} {{totalCount}} {{'TABLE.entries' | translate}}
          </div>
        </div>
        <!-- Pagination -->
        <div
          class="col-sm-12 col-md-5 d-flex align-items-center justify-content-end"
        >
          <div class="text-md-right float-md-right pagination-rounded">
            <ngb-pagination
              [collectionSize]="totalCount"
              [(page)]="pageIndex"
              [pageSize]="pageSize"
              [maxSize]="5"
              [ellipses]="true"
              (pageChange)="fetchData()"
            >
            </ngb-pagination>
          </div>
          <div class="dataTables_length ml-3" id="tickets-table_length">
            <label class="d-inline-flex align-items-center mb-0">
              {{'TABLE.Show' | translate}}
              <select
                name="tickets-table_length"
                aria-controls="tickets-table"
                name="pageSize"
                [(ngModel)]="pageSize"
                (ngModelChange)="pageIndex = 1;fetchData()"
                class="custom-select custom-select-sm form-control form-control-sm mx-2"
              >
                <option [ngValue]="10">10</option>
                <option [ngValue]="25">25</option>
                <option [ngValue]="50">50</option>
                <option [ngValue]="100">100</option>
              </select>
              {{'TABLE.entries' | translate}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #content role="document" let-modal="close">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ formData.value.id ? "FORM.Edit" : "Thêm cửa hàng" | translate }}
      </h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <div class="text-left">
        <form (ngSubmit)="saveData()" [formGroup]="formData">
          <div class="row">
            <div class="row m-0 p-0">
              <div class="form-group col-12">
                <label class="col-5 col-form-label required_label"
                  >{{ 'Người dùng' | translate }}</label
                >
                <ng-select
                  formControlName="userId"
                  class="form-select"
                  aria-label="Default select example"
                  class="col-md-12"
                >
                  <ng-option [value]="" hidden>Chọn người dùng</ng-option>
                  <ng-option
                    value="{{item.id}}"
                    *ngFor="let item of listDataUser"
                  >
                    {{ item.id }} - {{ item.full_name }} - {{ item.phone }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-12">
                <label class="col-5 col-form-label required_label"
                  >{{ 'Tên cửa hàng' | translate }}</label
                >
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="name"
                  />
                </div>
              </div>
              <div class="form-group col-12">
                <label class="col-5 col-form-label required_label"
                  >{{ 'Địa chỉ' | translate }}</label
                >
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="terminalAddress"
                  />
                </div>
              </div>
              <div class="form-group col-12">
                <label class="col-5 col-form-label required_label"
                  >{{ 'Ngân hàng' | translate }}</label
                >
                <ng-select
                  formControlName="binNumber"
                  class="form-select"
                  aria-label="Default select example"
                  class="col-md-12"
                >
                  <ng-option [value]="" hidden>Chọn ngân hàng</ng-option>
                  <ng-option value="{{item.id}}" *ngFor="let item of listBankname"
                    >{{ item.name }}</ng-option
                  >
                </ng-select>
              </div>
              <div class="form-group col-12">
                <label class="col-5 col-form-label required_label"
                  >{{ 'Số tài khoản' | translate }}</label
                >
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="accountNumber"
                    [(ngModel)]="accountNumber"
                    (blur)="onInputBlur()"
                  />
                </div>
              </div>
              <span
                class="text-danger font-italic font-size-12"
                *ngIf="!searching"
              >
                Cần nhập tài khoản ngân hàng để thêm cửa hàng
              </span>
  
              <div class="form-group col-12" *ngIf="searching">
                <label class="col-5 col-form-label required_label"
                  >{{ 'Chủ tài khoản' | translate }}</label
                >
                <div class="col-12">
                  <input
                    type="text"
                    [ngModel]="bank_accountName"
                    formControlName="terminalBankAccountName"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="text-center" *ngIf="searching">
            <button
              type="button"
              class="btn btn-secondary btn-lg mr-3"
              style="width: 150px;"
              (click)="modal('close click')"
            >
              {{ 'Đóng' | translate }}
            </button>
            <button
              type="submit"
              class="btn btn-success btn-lg"
              style="width: 150px;"
            >
              {{ 'Lưu' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
  
  <ng-template #contentLink role="document" let-modal="close">
    <div class="modal-header">
      <h5 class="modal-title">
        {{"Liên kết thiết bị và tài khoản" | translate}}
      </h5>
      <!-- <div *ngIf="makeForm('password').hasError('required')" class="error-message">
              Mật khẩu không được bỏ trống.
          </div> -->
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <div class="text-left">
        <form (ngSubmit)="saveData2()" [formGroup]="formData2">
          <div class="row">
            <div class="form-group row col-md-12">
              <div class="col mt-3">
                <div class="d-flex">
                  <label class="col-md-4 col-form-label required_label"
                    >{{'Thiết bị' |translate}}</label
                  >
                  <div class="col-md-8">
                    <ng-select formControlName="device_id">
                      <ng-option [value]="-1">Chọn thiết bị</ng-option>
                      <ng-option
                        [value]="item.device_id"
                        *ngFor="let item of listData2"
                      >
                        {{item.serial_number}}</ng-option
                      >
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row col-md-12">
              <div class="col mt-3">
                <div class="d-flex">
                  <label class="col-md-4 col-form-label required_label"
                    >{{'Tài khoản' |translate}}</label
                  >
                  <div class="col-md-8">
                    <ng-select formControlName="bankaccount_id">
                      <ng-option [value]="-1">Chọn tài khoản</ng-option>
                      <ng-option
                        [value]="item.bankaccount_id"
                        *ngFor="let item of listData3"
                      >
                        {{item.account_number}} - {{item.bank_account_name}} -
                        {{item.bank_name}}</ng-option
                      >
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <!-- <div class="text-right">
                      <button type="button" class="btn btn-secondary btn-sm mr-3"
                          (click)="modal('close click')">{{'Đóng' | translate}}</button>
                      <button type="submit" class="btn btn-primary btn-sm">{{'Lưu' | translate}}</button>
                  </div> -->
          <div class="text-center">
            <button
              type="button"
              class="btn btn-secondary btn-lg mr-3"
              style="width: 150px;"
              (click)="modal('close click')"
            >
              {{'Đóng' | translate}}
            </button>
            <button
              type="submit"
              class="btn btn-success btn-lg"
              style="width: 150px;"
            >
              {{'Lưu' | translate}}
            </button>
            <!-- <button type="button" class="btn btn-secondary btn-sm" style="width: 150px"
                      (click)="modal('close click')">{{'FORM.Close' | translate}}</button>
                  <button type="button" class="btn btn-primary btn-sm ml-3" style="width: 150px"
                      (click)="updateFormNote()">{{'FORM.Save' | translate}}</button> -->
          </div>
        </form>
      </div>
    </div>
  </ng-template>
  
  <ng-template #contentKeyword let-modalHis="close">
    <div class="modal-header">
      <h5 class="modal-title">List keyword of "{{selectedIncident.name}}"</h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modalHis('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <div class="mb-3" *ngIf="formNewKeyword">
        <h5 class="font-size-14">Add New Keyword</h5>
        <form
          [formGroup]="formNewKeyword"
          (submit)="onSubmitFormKeyword(2)"
          class="d-flex"
        >
          <input
            type="text"
            class="form-control form-control-sm"
            style="width: 250px;"
            formControlName="name"
          />
          <button class="btn btn-primary btn-sm ml-3" style="width: 150px;">
            Add
          </button>
        </form>
      </div>
      <div class="table-responsive">
        <table
          id="basic-datatable"
          class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
        >
          <thead>
            <tr>
              <th width="50">#</th>
              <th>Name</th>
              <!-- <th>Thời gian</th> -->
              <th width="170"></th>
            </tr>
          </thead>
          <tbody *ngFor="let data of dataKeyword;let i=index">
            <tr>
              <td>{{i+1}}</td>
              <td>
                <ng-container
                  *ngIf="selectedKeyword?.id == data.id && formEditKeyword; else normal"
                >
                  <form
                    [formGroup]="formEditKeyword"
                    (submit)="onSubmitFormKeyword(1)"
                  >
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      formControlName="name"
                    />
                  </form>
                </ng-container>
                <ng-template #normal>{{data.name}}</ng-template>
              </td>
              <!-- <td>{{data.created_at*1000 | date:'dd/MM/yyyy HH:mm'}}</td> -->
              <td>
                <div class="d-flex" style="gap: 10px;">
                  <a
                    class="btn btn-sm btn-success"
                    href="javascript: void(0);"
                    (click)="editKeyword(data,i)"
                  >
                    <i class="mdi mdi-account-edit"></i>{{'FORM.Edit' |
                    translate}}</a
                  >
                  <a
                    class="text-white btn btn-sm btn-danger"
                    (click)="onDeleteKeyword(data.id)"
                    ><i class="mdi mdi-delete"></i>{{'FORM.Delete' |
                    translate}}</a
                  >
                </div>
                <!-- <button class="btn" (click)="editKeyword(data,i)">Sửa</button>
                              <button>Xoá</button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template>
  