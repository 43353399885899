<div class="table-responsive">
    <table id="basic-datatable" class="table table-bordered dt-responsive nowrap  no-footer dtr-inline">
        <thead>
            <tr>
                <!-- <th>#</th> -->
                <th>#</th>
                <!-- <th>{{'PAGE.REPORT.Type' | translate}}</th> -->
                <th>{{'Rating' | translate}}</th>
                <th>{{'Comment' | translate}}</th>
                <!-- <th>{{'PAGE.REPORT.Location' | translate}}</th> -->
                <!-- <th>Detail</th> -->
                <th>{{'PAGE.REPORT.Status' | translate}}</th>
                <th>{{'TABLE.CreatedDate' | translate}}</th>
                <!-- <th>Ngày tạo đơn</th>-->
                <th>{{'TABLE.UpdatedDate' | translate}}</th>
                <!-- <th>{{'TABLE.Actions' | translate}}</th> -->
            </tr>
        </thead>
        <tbody *ngFor="let data of listData;let i=index">
            <tr (click)="onDetailBooking(data, onDetailBooking)" class="clickable">
                <td>{{i+(pageSize*pageIndex - pageSize + 1)}}</td>
                <!-- <td style="text-decoration: underline; color: #204cd9 ;"
                    (click)="onDetailBooking(data, detailBooking)">#{{data?.id}}</td> -->
                <!-- <td>
                    {{data?.id}}
                </td> -->
                <td>
                    {{data?.code}}
                </td>
                <td>
                    {{data?.details}}
                </td>
                <!-- <td>
                    <ng-container *ngIf="data.location_id; else location_other">
                        {{data?.location_detail?.name}}
                    </ng-container>
                    <ng-template #location_other>
                        {{data?.location_other?.formatted_address}}
                    </ng-template>
                </td> -->
                <!-- <td>{{data?.details.}}</td> -->
                <!-- <td>
                    <span class="badge badge-success" *ngIf="data.status == 'waiting'">Đơn hàng mới</span>
                    <span class="badge badge-danger" *ngIf="data.status == 'accepted'">Đã được thợ nhận</span>
                    <span class="badge badge-danger" *ngIf="data.status == 'reject'">Đơn hàng bị từ chối</span>
                    <span class="badge badge-danger" *ngIf="data.status == 'on_doing'">Đang tiến hành</span>
                    <span class="badge badge-danger" *ngIf="data.status == 'completed'">Đơn hàng hoàn
                        thành</span>
                    <span class="badge badge-danger" *ngIf="data.status == 'canceled'">Đơn hàng bị huỷ</span>
                </td> -->

                <td>
                    <span class="font-size-12 badge badge-danger" *ngIf="data.status == '0'">{{'Reject' |
                        translate}}</span>
                    <span class="font-size-12 badge badge-warning" *ngIf="data.status == '2'">{{'Pending' |
                        translate}}</span>
                    <span class="font-size-12 badge badge-success" *ngIf="data.status == '1'">{{'Done' |
                        translate}}</span>
                    <!-- <span class="badge badge-danger" *ngIf="data.status == 'completed'">Đơn hàng hoàn
                        thành</span>
                    <span class="badge badge-danger" *ngIf="data.status == 'canceled'">Đơn hàng bị huỷ</span> -->
                </td>
                <td>{{data.created_at | date:'dd/MM/yyyy HH:mm'}}</td>
                <td>{{data.updated_at | date:'dd/MM/yyyy HH:mm'}}</td>

                <!-- <td> -->
                    <!-- <a class="dropdown-item" (click)="onDetailBooking(data, detailBooking)">
                        <i class="mdi mdi-account-edit mr-1"></i>{{'PAGE.REPORT.ViewDetail' | translate}}
                    </a>
                    <a class="dropdown-item" *ngIf="data.images?.length > 0"
                        (click)="onEdit(data, content)">
                        <i class="mdi mdi-folder-multiple-image mr-1"></i>{{'PAGE.REPORT.ViewImage' |
                        translate}}
                    </a> -->

                    <!-- <a class="dropdown-item" href="javascript: void(0);" (click)="onDone(data)">
                        <i class="mdi mdi-check"></i> Hoàn thành đơn
                    </a>
                    <a class="dropdown-item" href="javascript: void(0);" (click)="onDelete(data)">
                        <i class="mdi mdi-delete"></i>Huỷ đơn
                    </a> -->

                    <!-- <div ngbDropdown placement="bottom-left">
                      <i class="mdi mdi-dots-vertical dropdown-toggle" ngbDropdownToggle
                          data-toggle="dropdown" aria-expanded="true"></i>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>

                      </div>
                  </div> -->
                <!-- </td> -->
            </tr>

        </tbody>
    </table>
</div>