<!-- <app-pagetitle title="{{'PAGE.REPORT.IncidentType' | translate}}" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->

<div class="card">
    <div class="card-body">
        <h5>{{'Danh sách tài khoản ngân hàng' | translate}}</h5>

        <div class="d-flex justify-content-end">
            <!--  -->
            <button class="btn btn-success mb-2" (click)="openModal(content)">
                <i class="mdi mdi-plus mr-2"></i> {{'TABLE.AddMore' | translate}}
            </button>
        </div>
        <div class="row mb-md-2">

        </div>
        <div class="table-responsive">
            <table id="basic-datatable" class="table table-bordered dt-responsive nowrap  no-footer dtr-inline">
                <thead>
                    <tr>
                        <th>{{'#' | translate}}</th>
                        <th>{{'Tên ngân hàng' | translate}}</th>
                        <th>{{'Số tài khoản' | translate}}</th>
                      
                        <th>{{'Tên tài khoản' | translate}}</th>
                        
                        <!-- <th>{{'Tên ngân hàng' | translate}}</th>
                        <th>{{'Trạng thái' | translate}}</th>
                        <th>{{'Phiên bản' | translate}}</th>
                        <th>{{'Ngày tạo' | translate}}</th>
                        <th>{{'Ngày cập nhật' | translate}}</th>
                        <th width="100" class="table-column">{{'Quản lý' | translate}}</th> -->
                        <th>{{'Ngày tạo' | translate}}</th>
                        <th>{{'Ngày cập nhật' | translate}}</th>
                        <th>{{'Action' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr  *ngFor="let data of listData;let i=index">
                        <td>{{pageSize*(pageIndex-1) +i+1}}</td>
                        <td>{{data?.bank_name}}</td>
                        <td>{{data?.account_number}}</td>
                        <td>{{data?.account_name}}</td>
                        <td>{{data?.created_at}}</td>
                        <td>{{data?.updated_at}}</td>
                        <td class="py-2">
                            <div class="d-flex" style="gap: 5px">
                                <a class="font-size-18 text-success" ngbTooltip="{{'Chi tiết' | translate}}"
                                    (click)="viewDetails(data)">
                                    <i class="mdi mdi-eye"></i>

                                </a>
                                <a class="font-size-18" ngbTooltip="{{'Sửa' | translate}}" (click)="onEdit(data, content)">
                                    <i class="mdi mdi-pen"></i>

                                </a>
                                <a class="font-size-18 text-danger" ngbTooltip="{{'Xóa' | translate}}" (click)="onDelete(data)">
                                    <i class="mdi mdi-delete"></i>
                                </a>

                                <!-- <a class="font-size-18 text-success" ngbTooltip="{{'Chi tiết' | translate}}" (click)="viewDetails(data)">
                                        <i class="mdi mdi-eye"></i>
                        
                                    </a>
                                    <a class="font-size-18" ngbTooltip="{{'Sửa' | translate}}" href="javascript: void(0);">
                                        <i class="mdi mdi-pen"></i>
                                        
                                    </a>
                                    <a class="font-size-18 text-danger" ngbTooltip="{{'Xóa' | translate}}">
                                        <i class="mdi mdi-delete"></i> 
                                    </a> -->

                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="row justify-content-md-between align-items-md-center mt-2">
            <div class="col-sm-12 col-md-5">
                <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                    {{'TABLE.Showing' | translate}}
                    {{pageSize*pageIndex - pageSize + 1}}
                    {{'TABLE.to' | translate}}
                    {{pageSize*pageIndex}}
                    {{'TABLE.of' | translate}}
                    {{totalCount}}
                    {{'TABLE.entries' | translate}}
                </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-end">

                <div class="text-md-right float-md-right pagination-rounded">
                    <ngb-pagination [collectionSize]="totalCount" [(page)]="pageIndex" [pageSize]="pageSize"
                        (pageChange)="fetchData()">
                    </ngb-pagination>
                </div>
                <div class="dataTables_length ml-3" id="tickets-table_length">
                    <label class="d-inline-flex align-items-center mb-0"> {{'TABLE.Show' | translate}}
                        <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                            [(ngModel)]="pageSize" (ngModelChange)="pageIndex = 1;fetchData()"
                            class="custom-select custom-select-sm form-control form-control-sm mx-2">
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="25">25</option>
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                        </select>
                        {{'TABLE.entries' | translate}}
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #content role="document" let-modal="close">
    <div class="modal-header">
        <h5 class="modal-title">{{(formData.value.id ? "FORM.Edit" : "FORM.AddNew") | translate}}</h5>
        <!-- <div *ngIf="makeForm('password').hasError('required')" class="error-message">
            Mật khẩu không được bỏ trống.
        </div> -->
        <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-3">
        <div class="text-left">
            <form (ngSubmit)="saveData()" [formGroup]="formData">
                <div class="row">
                    <!-- <div class="form-group row col-md-6">
                        <label class="col-md-2 col-form-label required_label">{{'Imei' | translate}}</label>
                        <div class="col-md-10">
                            <input type="text" class="form-control" formControlName="imei"
                                [ngClass]="{'is-invalid': submitted && form.imei.errors}" />
                          
                        </div>
                    </div> -->
                    <div class="form-group row col-md-12">
                        <label class="col-md-2 col-form-label required_label">{{'Số tài khoản' | translate}}</label>
                        <div class="col-md-10">
                            <input type="text" class="form-control" formControlName="account_number"
                                [ngClass]="{'is-invalid': submitted && form.account_number.errors}" />
                          
                        </div>
                    </div>
                    <div class="form-group row col-md-12">
                        <label class="col-md-2 col-form-label required_label">{{'Chủ tài khoản' | translate}}</label>
                        <div class="col-md-10">
                            <input type="text" class="form-control" formControlName="account_name"
                                [ngClass]="{'is-invalid': submitted && form.account_name.errors}" />
                          
                        </div>
                    </div>
                    <div class="form-group row col-md-12">
                        <label class="col-md-2 col-form-label required_label">{{'Ngân hàng' | translate}}</label>
                        <div class="col-md-10">
                            <input type="text" class="form-control" formControlName="bank_name"
                                [ngClass]="{'is-invalid': submitted && form.bank_name.errors}" />
                          
                        </div>
                    </div>  
                </div>          

                <div class="text-right">
                    <button type="button" class="btn btn-secondary btn-sm mr-3"
                        (click)="modal('close click')">{{'Đóng' | translate}}</button>
                    <button type="submit" class="btn btn-primary btn-sm">{{'Lưu' | translate}}</button>
                </div>
            </form>
        </div>
    </div>
</ng-template>


<ng-template #contentKeyword let-modalHis="close">
    <div class="modal-header">
        <h5 class="modal-title">List keyword of "{{selectedIncident.name}}"</h5>
        <button type="button" class="close" aria-label="Close" (click)="modalHis('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-3">
        <div class="mb-3" *ngIf="formNewKeyword">
            <h5 class="font-size-14">Add New Keyword</h5>
            <form [formGroup]="formNewKeyword" (submit)="onSubmitFormKeyword(2)" class="d-flex">
                <input type="text" class="form-control form-control-sm" style="width: 250px" formControlName="name">
                <button class="btn btn-primary btn-sm ml-3" style="width: 150px">Add</button>
          </form>
        </div>
        <div class="table-responsive">
            <table id="basic-datatable" class="table table-bordered dt-responsive nowrap  no-footer dtr-inline">
                <thead>
                    <tr>
                        <th width="50">#</th>
                        <th>Name</th>
                        <!-- <th>Thời gian</th> -->
                        <th width="170"></th>

                    </tr>
                </thead>
                <tbody *ngFor="let data of dataKeyword;let i=index">
                    <tr>
                        <td>{{i+1}}</td>
                        <td>
                            <ng-container *ngIf="selectedKeyword?.id == data.id && formEditKeyword; else normal">
                              <form [formGroup]="formEditKeyword" (submit)="onSubmitFormKeyword(1)">
                                    <input type="text" class="form-control form-control-sm" formControlName="name">
                              </form>
                            </ng-container>
                            <ng-template #normal>{{data.name}}</ng-template>
                            </td>
                            <!-- <td>{{data.created_at*1000 | date:'dd/MM/yyyy HH:mm'}}</td> -->
                        <td>
                            <div class="d-flex" style="gap: 10px">
                              <a class=" btn btn-sm btn-success" href="javascript: void(0);" (click)="editKeyword(data,i)">
                                  <i class="mdi mdi-account-edit"></i>{{'FORM.Edit' | translate}}</a>
                              <a class="text-white btn btn-sm btn-danger" (click)="onDeleteKeyword(data.id)"><i class="mdi mdi-delete"></i>{{'FORM.Delete' | translate}}</a>
                            </div>
                            <!-- <button class="btn" (click)="editKeyword(data,i)">Sửa</button>
                            <button>Xoá</button> -->
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>

</ng-template>
