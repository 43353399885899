<div id="layout-wrapper">
  <app-horizontaltopbar></app-horizontaltopbar>
  <app-horizontalnavbar></app-horizontalnavbar>
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="main-content">
    <div class="page-content-Cs">
      <router-outlet></router-outlet>
    </div>
    <!-- <app-footer-Cs></app-footer-Cs> -->
  </div>

  <app-rightsidebar></app-rightsidebar>
</div>
<!-- END layout-wrapper -->
