<div class="card">
  <div class="card-body">
    <div class="mb-0">
      <div class="mb-2">
        <div class="row modal-content border border-dark ml-0 mb-4 p-0">
          <div class="col col-md-12">
            <div>
              <button
                class="btn btn-danger btn-sm mt-2"
                (click)="handleCancel()"
              >
                Ngừng dịch vụ
                <i class="mdi mdi-close-network"></i>
              </button>
            </div>
            <div class="d-md-flex justify-content-center">
              <div class="col col-md-6 m-0 p-1">
                <div class="card-body rounded-top row">
                  <div class="col-2">
                    <img
                      src="../../../assets/images/store.png"
                      class="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div class="col-10 p-2">
                    <div class="d-flex">
                      <p class="">#{{data.workspace_code}}</p>
                      <h2 class="ml-4 fs-5 font-weight-bold">
                        {{ data.workspace_name | translate }}
                      </h2>
                      <p
                        class="ml-4"
                        [ngClass]="{'text-danger': data.device_subscription_status === 'Inactive', 'text-success': data.device_subscription_status === 'Active'}"
                      >
                        {{ data.device_subscription_status }}
                        <!-- Trạng thái -->
                      </p>
                    </div>
                    <div class="d-flex">
                      <p class="">
                        Ngày tạo: {{ data.activation_date | date:'dd/MM/yyyy'}}
                      </p>
                      <p class="ml-4">Thành viên: {{countMember || 1}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col col-md-6 p-3 border-left my-2">
                <div class="container">
                  <div class="g-3 row row-cols-1 row-cols-md-2">
                    <div class="col">
                      <div class="card shadow-sm border border-warning">
                        <div class="mt-2 mb-2">
                          <p class="text-center font-weight-bold">
                            Tổng số tiền
                          </p>
                          <p
                            class="text-center text-success font-weight-bold m-0"
                          >
                            {{ data.discounted_price | number:'1.0-0'}}
                            <sup><ins>đ</ins></sup>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm border border-warning">
                        <div class="mt-2 mb-2">
                          <p class="text-center font-weight-bold">
                            Số giao dịch
                          </p>
                          <p class="text-center font-weight-bold m-0">
                            {{ countTransaction || 0 }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <hr />
            <div>
              <h2>Thông tin gói dịch vụ & loa</h2>
              <div class="d-md-flex">
                <div class="col col-md-6 m-0 p-1">
                  <div class="card-body rounded-top row">
                    <div class="col-10 p-2">
                      <div class="d-flex">
                        <h2 class="fs-5 font-weight-bold">
                          Loại sản phẩm: {{ data.device_name | translate }}
                        </h2>
                        <p class="ml-4">
                          Số lượng: {{ data.quantity || '1'}}
                        </p>
                        <p class="ml-4">
                          Mã giới thiệu: {{ data.referrer || 'Không có'}}
                        </p>
                      </div>
                      <div class="d-flex">
                        <p class="">
                          Gói dịch vụ: {{ data.original_duration }} tháng
                        </p>
                        <p class="ml-4">Lợi ích: {{ data.benefit }}</p>
                      </div>
                      <div class="d-flex">
                        <p class="">
                          Ngày kích hoạt: {{ data.activation_date }}
                        </p>
                        <p class="ml-4">
                          Ngày hết hạn: {{ data.expiration_date }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col-md-6 p-3 border-left my-2">
                  <div class="container">
                    <div class="g-3 row row-cols-1 row-cols-md-2">
                      <div class="col">
                        <div class="card shadow-sm border border-warning">
                          <div class="mt-2 mb-2">
                            <p class="text-center font-weight-bold">Giá gốc</p>
                            <p
                              class="text-center text-danger font-weight-bold m-0"
                            >
                              {{ data.original_price | number:'1.0-0'}}
                              <sup><ins>đ</ins></sup>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="card shadow-sm border border-warning">
                          <div class="mt-2 mb-2">
                            <p class="text-center font-weight-bold">
                              Giá sau giảm
                            </p>
                            <p
                              class="text-center text-success font-weight-bold m-0"
                            >
                              {{ data.discounted_price | number:'1.0-0'}}
                              <sup><ins>đ</ins></sup>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <h2>Thông tin giao hàng</h2>
              <div class="d-md-flex">
                <div class="col col-md-6 m-0 p-1">
                  <div class="card-body rounded-top row">
                    <div class="col-10 p-2">
                      <div class="d-flex">
                        <p class="font-weight-bold">Trạng thái đơn hàng:</p>
                        <p class="ml-2">{{ data.status }}</p>
                      </div>
                      <div class="d-flex">
                        <p class="font-weight-bold">Địa chỉ giao hàng:</p>
                        <p class="ml-2">{{ data.terminal_address }}</p>
                      </div>
                      <div class="d-flex">
                        <p class="font-weight-bold">Người nhận:</p>
                        <p class="ml-2">{{ data.full_name }}</p>
                      </div>
                      <div class="d-flex">
                        <p class="font-weight-bold">Số điện thoại:</p>
                        <p class="ml-2">{{ data.user_phone }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col-md-6 p-3 border-left my-2">
                  <div class="container">
                    <div class="g-3 row row-cols-1 row-cols-md-2">
                      <div class="col">
                        <div class="card shadow-sm border border-warning">
                          <div class="mt-2 mb-2">
                            <p class="text-center font-weight-bold">
                              Đơn vị giao hàng
                            </p>
                            <p class="text-center font-weight-bold m-0">
                              {{ data.shipping_unit }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="card shadow-sm border border-warning">
                          <div class="mt-2 mb-2">
                            <p class="text-center font-weight-bold">
                              Thời gian giao hàng dự kiến
                            </p>
                            <p class="text-center m-0">
                              {{ data.expected_delivery_time || 'Chưa có' }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="col col-md-12 bg-success border rounded-top">
            <span class="text-white" style="font-size: 20px;"
              >Danh sách thiết bị trong đơn hàng</span
            >
          </div>
          <div class="table-responsive">
            <table
              id="basic-datatable"
              class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
            >
              <thead>
                <tr class="text-center bg-table">
                  <th>{{'#' | translate}}</th>
                  <th>{{'Mã người dùng' | translate}}</th>
                  <th>{{'Mã thiết bị' | translate}}</th>
                  <th>{{'Model' | translate}}</th>
                  <th>{{'Trạng thái' | translate}}</th>
                  <th>{{'Ngày tạo' | translate}}</th>
                  <th>{{'Ngày hết hạn' | translate}}</th>
                  <th>{{'Quản lý' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-center"
                  *ngFor="let data2 of listDataQR;let i = index"
                >
                  <td>{{pageSize*(pageIndex-1) +i+1}}</td>

                  <td>{{data2.user_id}}</td>
                  <td>{{data2.serial_number}}</td>
                  <td>{{data2.model}}</td>
                  <td
                    [ngClass]="{'text-danger': data2.status === 'Inactive', 'text-success': data2.status === 'Active'}"
                  >
                    {{ data2.status }}
                  </td>
                  <td>{{data2.activation_date | date:'dd/MM/yyyy'}}</td>
                  <td>{{data2.expiration_date | date:'dd/MM/yyyy'}}</td>
                  <td>
                    <div class="d-flex justify-content-around">
                      <a
                        (click)="onEdit(data2, contentQR)"
                        class="font-size-18 text-success"
                        ngbTooltip="{{'Hiển thi QR' | translate}}"
                        placement="left"
                      >
                        <i class="mdi mdi-qrcode-scan"></i>
                      </a>
                      <!-- <a
                        class="font-size-18 text-danger"
                        ngbTooltip="{{'Ngưng dịch vụ' | translate}}"
                        placement="left"
                      >
                        <i class="mdi mdi-close-network"></i>
                      </a> -->
                      <a
                        (click)="onChange(data2, contentLink)"
                        class="font-size-18 text-primary"
                        ngbTooltip="{{'Đổi thiết bị' | translate}}"
                        placement="left"
                      >
                        <i class="mdi mdi-swap-horizontal"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="">
          <div class="col col-md-12 bg-success border rounded-top">
            <span class="text-white" style="font-size: 20px;"
              >Lịch sử thao tác</span
            >
          </div>
          <div class="table-responsive">
            <table
              id="basic-datatable"
              class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
            >
              <thead>
                <tr class="text-center bg-table">
                  <th>{{'#' | translate}}</th>
                  <th>{{'Người tác động' | translate}}</th>
                  <th>{{'Mã người dùng' | translate}}</th>
                  <th>{{'Thao tác' | translate}}</th>
                  <th>{{'Ngày tác động' | translate}}</th>
                  <!-- <th>{{'Số tiền' | translate}}</th> -->
                  <!-- <th>{{'Trạng thái' | translate}}</th> -->
                  <!-- <th>{{'Ngày giao dịch' | translate}}</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-center"
                  *ngFor="let data2 of listDataLog;let i = index"
                >
                  <td>{{pageSize*(pageIndex-1) +i+1}}</td>

                  <!-- <td *ngIf="role ==='admin'">{{data.phone}}</td> -->
                  <!-- <td *ngIf="role ==='admin'">{{data.workspace_name}}</td> -->
                  <td>{{data2.full_name}}</td>
                  <td>{{data2.user_id}}</td>
                  <td>{{data2.action}}</td>
                  <td>{{data2.datetime}}</td>
                  <!-- <td>{{data2.total}}</td> -->
                  <!-- <td>    
                                          <span class="font-size-12 badge badge-danger" *ngIf="data2.status == '0'">{{'Từ chối' |
                                              translate}}</span>
                                          <span class="font-size-12 badge badge-warning" *ngIf="data2.status == '2'">{{'Đang xử lý' |
                                              translate}}</span>
                                          <span class="font-size-12 badge badge-success" *ngIf="data2.status == '1'">{{'Đã duyệt' |
                                              translate}}</span>
                                      </td> -->
                  <!-- <td>{{data2.created_at | date:'dd/MM/yyyy'}}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <!--  -->
      <!-- <button class="btn btn-success mb-2" (click)="openModal(content)">
                <i class="mdi mdi-plus mr-2"></i> Th4m dịch vụ
            </button> -->
    </div>
  </div>
</div>

<ng-template #contentLink role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      {{"Thay đổi thiết bị" | translate}}
    </h5>
    <!-- <div *ngIf="makeForm('password').hasError('required')" class="error-message">
              Mật khẩu không được bỏ trống.
          </div> -->
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="deviceLink()" [formGroup]="formData">
        <div class="row">
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{'Thiết bị' | translate}}</label
            >
            <div class="col-md-10">
              <input
                disabled
                type="text"
                class="form-control"
                formControlName="serial_number"
                [ngClass]="{'is-invalid': submitted && form.serial_number.errors}"
              />
            </div>
          </div>
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{'Thiết bị mới' |translate}}</label
            >
            <div class="col-md-10">
              <ng-select
                [items]="listDevices"
                bindLabel="serial_number"
                bindValue="serial_number"
                formControlName="serial_number_new"
                placeholder="{{ 'Chọn thiết bị mới' | translate }}"
                [ngClass]="{'is-invalid': submitted && form.serial_number_new.errors}"
              >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-3"
            style="width: 150px;"
            (click)="modal('close click')"
          >
            {{'Đóng' | translate}}
          </button>
          <button
            type="submit"
            class="btn btn-success btn-lg"
            style="width: 150px;"
          >
            {{'Lưu' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #contentMember role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">{{("Thêm thành viên") | translate}}</h5>
    <!-- <div *ngIf="makeForm('password').hasError('required')" class="error-message">
              Mật khẩu không được bỏ trống.
          </div> -->
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="saveDataMember()" [formGroup]="formDataMember">
        <div class="row">
          <!-- <div class="form-group row col-md-6">
                          <label class="col-md-2 col-form-label required_label">{{'Imei' | translate}}</label>
                          <div class="col-md-10">
                              <input type="text" class="form-control" formControlName="imei"
                                  [ngClass]="{'is-invalid': submitted && form.imei.errors}" />
                            
                          </div>
                      </div> -->
          <!-- <div class="form-group row col-md-12" *ngIf="!formData.value.id"> -->
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{'Số điện thoại' | translate}}</label
            >
            <div class="col-md-8">
              <input
                type="phone"
                class="form-control"
                formControlName="phone"
                [ngClass]="{'is-invalid': submitted && form.phone.errors}"
                [(ngModel)]="phone"
              />
            </div>
            <div class="col-md-2">
              <button
                type="button"
                class="text-white btn btn-info"
                (click)="submitFilter()"
                style="width: inherit;"
              >
                Tìm kiếm
              </button>
            </div>
          </div>
          <div
            class="row col-md-12 m-4"
            style="padding-left: 7rem;"
            *ngIf="searching"
          >
            <img
              class="mr-4 rounded-circle avtFilter"
              src="assets/images/users/avatar-9.png"
              alt="Avatar"
            />
            <div
              class="d-flex flex-column align-items-start justify-content-center"
            >
              {{phoneResultsName}} - {{phoneResultsAddress}}
            </div>
          </div>
          <div class="form-group row col-md-12" *ngIf="searching">
            <label class="col-md-2 col-form-label required_label"
              >{{'Vai trò' | translate}}</label
            >
            <!-- <div class="col-md-10">
                              <input type="number" class="form-control" formControlName="version"
                                  [ngClass]="{'is-invalid': submitted && form.version.errors}" />
                            
                          </div> -->
            <div class="col-md-10">
              <select
                class="form-select"
                aria-label="Default select example"
                class="form-control"
                formControlName="type"
                [ngClass]="{'is-invalid': submitted && form.type.errors}"
              >
                <option value="{{item}}" *ngFor="let item of listType"
                  >{{item}}</option
                >
              </select>
            </div>
          </div>
        </div>

        <!-- <div class="text-right">
                      <button type="button" class="btn btn-secondary btn-sm mr-3"
                          (click)="modal('close click')">{{'Đóng' | translate}}</button>
                      <button type="submit" class="btn btn-primary btn-sm">{{'Lưu' | translate}}</button>
                  </div> -->
        <div class="text-center" *ngIf="searching">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-3"
            style="width: 150px;"
            (click)="modal('close click')"
          >
            {{'Đóng' | translate}}
          </button>
          <button
            bmitype="sut"
            class="btn btn-success btn-lg"
            style="width: 150px;"
          >
            {{'Lưu' | translate}}
          </button>
          <!-- <button type="button" class="btn btn-secondary btn-sm" style="width: 150px"
                      (click)="modal('close click')">{{'FORM.Close' | translate}}</button>
                  <button type="button" class="btn btn-primary btn-sm ml-3" style="width: 150px"
                      (click)="updateFormNote()">{{'FORM.Save' | translate}}</button> -->
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #contentQR role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      Hiển thị QR
    </h5>
    <!-- <div *ngIf="makeForm('password').hasError('required')" class="error-message">
                Mật khẩu không được bỏ trống.
            </div> -->
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form [formGroup]="formData">
        <div class="row">
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{'Serial' | translate}}</label
            >
            <div class="col-md-10">
              <input
                disabled
                type="text"
                class="form-control"
                formControlName="serial_number"
                [ngClass]="{'is-invalid': submitted && form.serial_number.errors}"
              />
            </div>
          </div>
          <!-- <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{'Số tài khoản' | translate}}</label
            >
            <div class="col-md-10">
              <input
                disabled
                type="text"
                class="form-control"
                formControlName="serial_number"
                [ngClass]="{'is-invalid': submitted && form.serial_number.errors}"
              />
            </div>
          </div>
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{'Chủ tài khoản' | translate}}</label
            >
            <div class="col-md-10">
              <input
                disabled
                type="text"
                class="form-control"
                formControlName="serial_number"
                [ngClass]="{'is-invalid': submitted && form.serial_number.errors}"
              />
            </div>
          </div> -->
          <div class="form-group row col-md-12">
            <div class="col-md-12 d-block justify-content-center">
              <div class="d-flex col-md-12 justify-content-center">
                <div class="borderQR m-auto" *ngIf="qrCodeValue">
                  <img
                    src="assets/images/qrcode_border.png"
                    style="width: 330px; height: 330px;"
                    alt=""
                  />
                  <div class="borderGenQR">
                    <div class="m-auto qrcode-pay">
                      <qr-code
                        class="m-auto"
                        value="{{qrCodeValue}}"
                        size="170"
                        errorCorrectionLevel="M"
                        margin="4"
                        margin-left="35"
                      >
                      </qr-code>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-center mt-3"
                *ngIf="flagDownQr"
              >
                <div class="col-12 col-md-3 p-0">
                  <button class="btn-viettel col" (click)="downloadQRCode()">
                    <span class="m-auto">Tải xuống mã QR</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-3"
            style="width: 150px;"
            (click)="modal('close click')"
          >
            {{'Đóng' | translate}}
          </button>
          <!-- <button
            type="submit"
            class="btn btn-success btn-lg"
            style="width: 150px;"
          >
            {{'Lưu' | translate}}
          </button> -->
          <!-- <button type="button" class="btn btn-secondary btn-sm" style="width: 150px"
                        (click)="modal('close click')">{{'FORM.Close' | translate}}</button>
                    <button type="button" class="btn btn-primary btn-sm ml-3" style="width: 150px"
                        (click)="updateFormNote()">{{'FORM.Save' | translate}}</button> -->
        </div>
      </form>
    </div>
  </div>
</ng-template>
