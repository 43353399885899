<div class="card">
    <div class="card-body">
        <div class="media text-center" style="flex-direction: column; align-items: center;">
            <div *ngIf="icon" style="padding: 5px; width: 35px; text-align: center; border-radius:50%;" [style.backgroundColor]="bgColor">
                <i class="{{icon}} font-size-16" [style.color]="color"></i>
              </div>
            <div class="media-body mt-2 overflow-hidden">
                <h4 class="mb-2 mt-1">{{value}}</h4>
                <p class="text-truncate font-size-14 mb-0">{{title}}</p>
            </div>

        </div>
    </div>



    <!-- <div class="card-body border-top py-3">
  <div class="text-truncate">
    <span class="badge badge-soft-success font-size-11"><i class="mdi mdi-menu-up"> </i> 2.4% </span>
    <span class="text-muted ml-2">From previous period</span>
  </div>
</div> -->
</div>
