import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from './shared/shared.module';

import { VerticalMobileComponent } from './vertical/vertical.component';
import { HorizontalMobileComponent } from './horizontal/horizontal.component';
import { LayoutMobileComponent } from './layout/layout.component';


@NgModule({
  declarations: [VerticalMobileComponent, HorizontalMobileComponent, LayoutMobileComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,

  ],
  exports: [VerticalMobileComponent, HorizontalMobileComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutMobilesModule { }
