<header id="page-topbar">
  <div class="navbar-header-Cs btn-cs">
    <div class="d-flex">
      <!-- LOGO -->
      <!-- <div class="navbar-brand-box ">
                <a href="/" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="assets/images/logo-sm-dark.png" alt="" height="22">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/logo-no-background.png" alt="" height="20">
                    </span>
                </a>

                <a href="/" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="assets/images/logo-sm-light.png" alt="" height="22">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/logo_qrpaybox-bup.png" alt="" width="185">
                    </span>
                </a>
            </div> -->
      <button
        type="button"
        class="btn btn-sm px-3 text-white font-size-24 waves-effect"
        id="vertical-menu-btn"
        (click)="goBack($event)"
      >
        <!-- <i class="fa-solid fa-shop"></i> -->
        <i class="ri-arrow-left-line"></i>
      </button>
    </div>
    <div class="d-flex">
      <div class="font-size-20">
        {{pageTitle}}
      </div>
    </div>
    <div class="d-flex">
      <div
        class="dropdown d-inline-block user-dropdown"
        ngbDropdown
        *ngIf="role == 'user'"
      >
        <button
          type="button"
          ngbDropdownToggle
          class="btn waves-effect custom-btn"
          id="page-header-user-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="d-flex">
            <img
              class="rounded-circle header-profile-user ml-2"
              src="assets/images/store.png"
              alt="ảnh cửa hàng"
            />
          </div>
        </button>
        <div
          class="dropdown-menu dropdown-menu-right justify-content-center p-3 rounded"
          ngbDropdownMenu
        >
          <h5>Danh sách cửa hàng</h5>
          <div
            class="overflow-auto custom-scrollbar"
            style="max-height: 200px;"
          >
            <div
              [ngClass]="{ 'active-workspace': activeWorkspace === data.code }"
              (click)="changeWorkspace(data.code, data.name)"
              *ngFor="let data of listData"
              href="javascript: void(0);"
            >
              <div class="custom-item-topbar d-flex custom-dropdown-item">
                <img
                  src="assets/images/store.png"
                  class="m-1"
                  alt="avatar"
                  width="50"
                />
                <div class="p-2">
                  <div>{{data.name}} - {{data.code}}</div>
                  <div>{{data.leader}}</div>
                </div>
                <i
                  *ngIf="data.code == activeWorkspace"
                  class="mdi mdi-check-circle text-success ml-auto"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
