<div class="card">
    <div class="card-body" style="min-height: 400px;">
        <div class="mb-0">
            <div class="mb-2">
                <div class="row">
                    <div class="d-flex col col-md-12">
                        <h3 style="margin: 0px !important;" class="col-md-8 pt-1">{{'Thông tin tài khoản ngân hàng' | translate}} 1</h3>
                        
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col col-md-12">
                        <!-- <p class="label_title col col-md-12">Thông tin chi tiết:</p> -->
                        <div class="d-flex">
                            <div class="col col-md-12">
                                <!--  -->

                                <div class="align-items-center m-4" style="border: 1.3px solid #c4c4c4; border-radius: 15px;">
                                    <div class="table-responsive">
                                        <table id="basic-datatable" class="table table-bordered dt-responsive nowrap  no-footer dtr-inline">
                                            <thead>
                                                <tr>
                                                <!-- <th>{{'#' | translate}}</th>                               -->
                                                <th>{{'Tên tài khoản' | translate}}</th>
                                                <th>{{'Số tài khoản' | translate}}</th>
                                                <th>{{'Chủ tài khoản' | translate}}</th>
                                                <th>{{'Địa chỉ' | translate}}</th>
                                                <th>{{'Tên ngân hàng' | translate}}</th>
                                                <th>{{'Số điện thoại' | translate}}</th>
                                                <!-- <th>{{'Ngày cập nhập' | translate}}</th> -->
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <!-- <td>{{pageSize*(pageIndex-1)}} </td> -->
                                                <td>{{data?.account_name}}</td>
                                                <td>{{data?.account_number}}</td>
                                                <td>{{data?.full_name}}</td>
                                                <td>{{data?.address}}</td>
                                                <td>{{data?.bank_name}}</td>
                                                <td>{{data.phone}}</td>
                                                <!-- <td>{{data?.created_at | date:'dd/MM/yyyy HH:mm':'GMT'}}</td> -->
                                                <!-- <td>{{data?.updated_at | date:'dd/MM/yyyy HH:mm':'GMT'}}</td> -->
                                
                                            </tr>
                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="d-flex justify-content-end">
        </div>

    </div>
</div>


