<div id="layout-wrapper">
  <app-topbar >
  </app-topbar>
  <!-- <app-sidebar></app-sidebar> -->
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="">
    <div class="page-content-mobile">
      <router-outlet></router-outlet>
    </div>
    <app-footer-mobile *ngIf="mobileFooterFlag" (mobileMenuButtonClicked)="onToggleMobileMenu()" (settingsButtonClicked)="onSettingsButtonClicked()"></app-footer-mobile>
  </div>
  <app-rightsidebar></app-rightsidebar>
</div>
<!-- END layout-wrapper -->
