<div class="card">
    <div class="card-body" style="min-height: 400px;">
        <div class="mb-0">
            <div class="mb-2">
                <div class="row">
                    <div class="d-flex col col-md-12">
                        <h3 style="margin: 0px !important;" class="col-md-8 pt-1">{{'Thông tin mã QR' | translate}} 1</h3>
                        <!-- <div class="col-md-4 d-flex" style="gap: 5px;">
                            <button class="btn btn-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer-fill" viewBox="0 0 16 16">
                                <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1"/>
                                <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"/>
                              </svg>
                              In
                            </button>
                            <button class="btn btn-primary">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text-fill" viewBox="0 0 16 16">
                                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M5 4h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1m-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1m0 2h3a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1"/>
                                </svg>
                                Xuất hóa đơn
                            </button>
                            <button class="btn btn-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capslock-fill" viewBox="0 0 16 16">
                                    <path d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816L7.27 1.047zM4.5 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1z"/>
                                  </svg>
                                Xuất file
                            </button>

                        </div> -->
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col col-md-12">
                        <!-- <p class="label_title col col-md-12">Thông tin chi tiết:</p> -->
                        <div class="d-flex">
                            <div class="col col-md-4">
                                <div>
                                    <img class=" mb-4 img-thumbnail rounded mx-auto d-block" src="https://docs.lightburnsoftware.com/img/QRCode/ExampleCode.png" style="width: 145px;">
                                </div>
                                <div class="d-flex justify-content-center" style="gap: 12px;">
                                    <button class="btn btn-dark d-flex" style="gap: 5px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer-fill mt-1" viewBox="0 0 16 16">
                                            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1"/>
                                            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"/>
                                          </svg>
                                        <span>In mã QR</span>
                                    </button>
                                    <button class="btn btn-success d-flex" style="gap: 5px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                                          </svg>
                                        <span>Lưu về máy</span>
                                    </button>
                                </div>
                            </div>
                            <div class="col col-md-8">
                                <div class="d-flex">
                                    <p class="col col-md-6 label_title color_title_second color_title_second label_title ">Mã sản phẩm:</p>
                                    <p class="col col-md-6"> data.code</p>
                                </div>
                                <div class="d-flex">
                                    <p class="col col-md-6 label_title color_title_second color_title_second label_title">Tên sản phẩm:</p>
                                    <p class="col col-md-6">data.full_name</p>
                                </div>
                                <div class="d-flex">
                                    <p class="col col-md-6 label_title color_title_second color_title_second label_title">Giá tiền:</p>
                                    <p class="col col-md-6">1200000 VND</p>
                                </div>
                                <div class="d-flex">
                                    <p class="col col-md-6 label_title color_title_second color_title_second label_title">Thời hạn sản phẩm:</p>
                                    <p class="col col-md-6">2025/05/23 23:59:59</p>
                                </div>
                                <div class="d-flex">
                                    <p class="col col-md-6 label_title color_title_second color_title_second label_title">Ngày tạo sản phẩm:</p>
                                    <p class="col col-md-6">2023/05/23 23:59:59</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col col-md-12">
                        <p class="label_title col col-md-12">Thông tin tài khoản ngân hàng</p>
                        <div class="d-flex">
                            <div class="col col-md-4">
                                <div *ngIf="data" class="align-items-center">
                                    <div class="d-flex flex-column align-items-center">
                                        <div class="d-flex flex-column m-4 w-75" style="background: linear-gradient(67.45deg, #278181 -1.69%, #71D8D8 15.97%, #49A9A9 32.39%, #71D8D8 49.33%, #71D8D8 61.51%, #389595 80.58%, #71D8D8 92.76%),
                                    linear-gradient(108.78deg, #E9E8E8 13.22%, #F1F1F1 33.33%, #DCDCDC 54.71%, #F0F0F0 100%);
                                    ; border: 0.91px solid;border-radius: 2rem; border-image-source: linear-gradient(108.78deg, #E9E8E8 13.22%, #F1F1F1 33.33%, #DCDCDC 54.71%, #F0F0F0 100%); margin-left: 1rem;">
                                        <div class="m-4">QRPaysBox</div>
                                        <div class="d-flex justify-content-center align-items-center flex-column m-4 flex-grow-1">
                                            <div>{{data.full_name}}</div>
                                            <div>*** ***{{data.customer_code}}</div>
                                        </div>
                                    </div>
                                    </div>
                                </div>    
                            </div>
                            <div class="col col-md-8 mt-4">
                                <div class="d-flex">
                                    <p class="col col-md-6 label_title color_title_second color_title_second label_title">Ngân hàng:</p>
                                    <p class="col col-md-6">{{data.bank_name || 'không có'}}</p>
                                </div>
                                <div class="d-flex">
                                    <p class="col col-md-6 label_title color_title_second color_title_second label_title">Số tài khoản:</p>
                                    <p class="col col-md-6">{{data.bank_account || 'không có'}}</p>
                                </div>
                                <div class="d-flex">
                                    <p class="col col-md-6 label_title color_title_second color_title_second label_title">Chủ tài khoản:</p>
                                    <p class="col col-md-6">{{data.bank_account_name || 'không có'}}</p>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <hr> -->
                <!-- <div class="row">
                    <div class="col col-md-12">
                        <p class="label_title col col-md-12">Lịch sử giao dịch</p>
                        <div class="align-items-center m-4" style="border: 1.3px solid #c4c4c4; border-radius: 15px;">
                            <div class="table-responsive">
                                <table id="basic-datatable" class="table table-bordered dt-responsive nowrap  no-footer dtr-inline">
                                    <thead>
                                        <tr>
                                        <th>{{'#' | translate}}</th>                              
                                        <th>{{'Số tiền' | translate}}</th>
                                        <th>{{'Trạng thái' | translate}}</th>
                                        <th>{{'Ngày giao dịch' | translate}}</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of listData;let i=index">
                                        <td>{{pageSize*(pageIndex-1)}} </td>
                                        <td>{{data?.total}}</td>
                                        <td>
                                            <span class="font-size-12 badge badge-danger" *ngIf="data.status == '0'">{{'Từ chối' |
                                                translate}}</span>
                                            <span class="font-size-12 badge badge-warning" *ngIf="data.status == '2'">{{'Đang xử lý' |
                                                translate}}</span>
                                            <span class="font-size-12 badge badge-success" *ngIf="data.status == '1'">{{'Đã duyệt' |
                                                translate}}</span>
                                             </td>
                                        <td>{{data?.created_at | date:'dd/MM/yyyy HH:mm':'GMT'}}</td>
                                    </tr>
                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <hr> -->
        
                <!-- End search -->
            </div>

        </div>
        <div class="d-flex justify-content-end">
            <!--  -->
            <!-- <button class="btn btn-success mb-2" (click)="openModal(content)">
              <i class="mdi mdi-plus mr-2"></i> Th4m dịch vụ
          </button> -->
        </div>

    </div>
</div>

<!-- <ng-template > -->

<!-- </ng-template> -->


