import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'leaflet';
import { EventService } from 'src/app/core/services/event.service';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private eventService: EventService,
    private translateService: TranslateService
    // private modalService: NzModalService,
    // private notification: NzNotificationService
  ) { }
  searchTerm
  listData = []
  selectedFile: File | null = null;
  pageSize = 10
  pageIndex = 1
  totalCount = 2
  tableName = 'devices-cs'
  role = JSON.parse(localStorage.getItem('currentUser'))['role']
  submitted: boolean
  formData: FormGroup
  listModel = ['MD712', 'MD716', 'MD760', 'Y50', 'Y56', 'F91']
  listVersion = ['Paybox', 'Soundbox']
  listWs = []
  listUser = []

  // listStatus = ['Trực tuyến' , 'Ngoại tuyến']
  breadCrumbItems

  get form() {
    return this.formData.controls
  }

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'PAGE.HOME' }, { label: 'PAGE.REPORT.IncidentType', active: true }];
    this.fetchData()
    this.getDataUsers()
  }
  filter = {
    type_id: -1,
    name: '',
    phone: '',
    // location_id: -1,
    status: -1,
    startdate: -1,
    enddate: -1
  }
  searching = false

  params = ''
  submitFilter() {
    this.params = ''
    Object.keys(this.filter).forEach(key => {
      console.log(key + '-' + this.filter[key])
      if (this.filter[key] || this.filter[key] >= 0) {
        this.params += `&${key}=${this.filter[key]}`
      }
    })

    if (this.params) {
      this.pageIndex = 1
      this.searching = true
      this.fetchData()
    }
  }
  selected: any;

  clearFilter() {
    this.selected = ''
    this.params = ''
    this.filter.name = ''
    this.filter.type_id = -1
    this.filter.status = -1
    this.filter.phone = ''
    this.filter.startdate = 0
    this.filter.enddate = 0
    this.pageIndex = 1
    // this.searching = false
    this.fetchData()
  }
  getDataUsers() {
    this.apiService.getList('users').subscribe({
      next: (res) => {
        this.listUser = res['users'] ? res['users'] : [];
      },
      error: (err) => {
        console.error(err);
      }
    })
  }

  onChangePage(e) {
    this.pageIndex = e
    this.fetchData()
  }
  getDataWs() {
    this.eventService.setLoading(true)
    this.apiService.getList('workspaces?userIdWeb=' + this.user_id).subscribe({
      next: (res) => {
        this.listWs = res?.workspaces ? res?.workspaces : [];
        this.eventService.setLoading(false)
      },
      error: (err) => {
        console.error(err);
        this.eventService.setLoading(false);
      }
    })
  }

  fetchData(params = '') {
    params = '&order=updated_at&direction=desc'
    this.eventService.setLoading(true)
    this.apiService.getList(this.tableName +
      `?page=${this.pageIndex}&limit=${this.pageSize}${params}`
    ).subscribe({
      next: (res) => {
        this.listData = res.metadata['devices'] ? res.metadata['devices'] : [];
        this.totalCount = res.metadata.pagination.totalCount ? res.metadata.pagination.totalCount : '';
        this.eventService.setLoading(false)
      }
    })
  }
  excelData: any;

  onFileChange(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }
  user_id
  onLink(data, content) {
    this.user_id = data.user_id
    this.getDataWs()
    this.formData = this.makeForm(data)
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }
  deviceLink(data) {

    this.apiService.postItem('workspaces/link-device/' + data.idWs,
      { deviceId: data.id, userId: this.user_id }
    ).subscribe({
      next: res => {
        this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant('Liên kết thành công'));
        this.submitted = false
        this.formData.reset()
        this.fetchData()
        this.modalService.dismissAll()

      },
      error: e => {

        this.apiService.showToastMobile(this.translateService.instant('error'), this.translateService.instant('Liên kết thất bại'));
        this.eventService.setLoading(false);
      }
    });
  }

  addLang() {
    const language = this.form.language as FormArray
    let lang = this.makePrice()
    language.push(lang)
  }

  removeLang(i) {
    const language = this.form.language as FormArray
    language.removeAt(i)
  }

  openModal(content: any) {
    this.submitted = false
    this.formData = this.makeForm({ language: [{}] })
    let a = this.modalService.open(content, { centered: true, scrollable: true, size: 'lg' });
  }

  onEdit(data, content) {
    console.log(data)
    this.viewQRcode(data)
    this.formData = this.makeForm(data)
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }
  qrCodeValue: string;
  flagDownQr = false
  viewQRcode(dataQR) {
    this.qrCodeValue = ''
    this.flagDownQr = false
    const data = {
      user_id: dataQR.user_id,
      amount: dataQR.amount,
      token: dataQR.token,
      mid: dataQR.serial_number,
      qrcodeType: 11
    }
    this.apiService.addQRCodeV2(data).subscribe({
      next: res => {
        this.qrCodeValue = res['data']['qr_string'] ? res['data']['qr_string'] : '',
          this.flagDownQr = true
        this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant("Tạo QR thành công"))
        this.eventService.setLoading(false)
      },
      error: e => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "warning",
          title: "Có lỗi xảy ra"
        });
        this.eventService.setLoading(false)
      }
    })
  }
  isWebView() {
    const userAgent = navigator.userAgent;

    return (userAgent.indexOf('FBAN') > -1) || (userAgent.indexOf('FBAV') > -1)
      || window.ReactNativeWebView
      || (userAgent.indexOf('wv') > -1)
      || (userAgent.indexOf('WebView') > -1)
      || (userAgent.indexOf('Android') > -1 && userAgent.indexOf('wv') > -1);
  }
  downloadQRCode() {
    const qrElement = document.querySelector('.qrcode-pay');

    if (qrElement) {
      // Ép kiểu qrElement từ Element sang HTMLElement
      html2canvas(qrElement as HTMLElement).then((canvas) => {
        const qrUrl = canvas.toDataURL('image/png');

        if (this.isWebView()) {
          // Nếu đang ở trong WebView, gửi yêu cầu đến ứng dụng native
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'download', url: qrUrl }));
          }
        } else {
          // Trên trình duyệt thông thường (desktop/mobile)
          const link = document.createElement('a');
          link.href = qrUrl;
          link.download = 'QR_Code.png';
          link.click();
        }
      });
    } else {
      console.error('QR code element not found.');
    }
  }
  selectedIncident = null
  selectedKeyword = null

  makeFormKeyword(d) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
      id: [data.id],
      name: [data.name],
      incident_type_id: [data.incident_type_id]
    })
  }
  deviceAdd(data) {
    const modifiedSerialNumber = data.serial_number
      ? data.serial_number.toUpperCase()
      : '';
    const dataPost = {
      model: data.model,
      serial_number: modifiedSerialNumber,
      version: 'Paybox',
      userId: data.userId
    }
    this.apiService.postItem('cs/devices',
      dataPost
    ).subscribe({
      next: res => {
        this.formData.reset()
        this.fetchData()
        this.modalService.dismissAll()
        this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant('Thêm mới thành công'));

      },
      error: e => {
        if (e == 'error.message.serialNumberExisted') {
          this.apiService.showToastMobile(
            this.translateService.instant('error'),
            this.translateService.instant('Thiết bị đã được liên kết'),
          );
        } else {
          this.apiService.showToastMobile(
            this.translateService.instant('error'),
            this.translateService.instant('FORM.ErrorMessageAdd'),
          );
        }
        this.eventService.setLoading(false);
      }
    });
  }
  fetchKeyword() {
    this.dataKeyword = []
    this.eventService.setLoading(true)
    this.apiService.getList(`keyword?type_id=${this.selectedIncident.id}`).subscribe({
      next: res => {
        console.log(res)
        this.dataKeyword = [...res]
        this.eventService.setLoading(false)
      }
    })
  }

  dataKeyword = []

  formEditKeyword: FormGroup
  formNewKeyword: FormGroup
  editKeyword(data, index) {
    this.formEditKeyword = this.makeFormKeyword(data)
    this.selectedKeyword = data
  }

  onSubmitFormKeyword(type) {
    if (type == 1) {
      if (this.formEditKeyword.valid) {
        const value = this.formEditKeyword.value
        this.eventService.setLoading(true)
        this.apiService.editItem('keyword', value.id, value).subscribe({
          next: res => {
            this.selectedKeyword = null
            this.fetchKeyword()
            this.eventService.setLoading(false)

          }
        })
      }
    } else {
      if (this.formNewKeyword.valid) {
        const value = this.formNewKeyword.value
        this.eventService.setLoading(true)
        this.apiService.addItem('keyword', value).subscribe({
          next: res => {
            this.eventService.setLoading(false)
            this.formNewKeyword.reset({ incident_type_id: this.selectedIncident.id })
            this.fetchKeyword()
          }
        })
      }
    }
  }

  onOpenKeywordModal(content, incident) {
    this.selectedIncident = incident
    this.fetchKeyword()
    this.formNewKeyword = this.makeFormKeyword({ incident_type_id: this.selectedIncident.id })
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }

  defaultPrices = [
    { condition: 1, price: 0, unit: '' },
    { condition: 2, price: 0, unit: '' }
  ]

  onDeleteKeyword(id) {
    if (id) {
      this.eventService.setLoading(true)
      this.apiService.deleteItem('keyword', id).subscribe(res => {

        Swal.fire(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessageDelete'), 'success')
          .then(
            res => {
              this.fetchKeyword();

            }
          )
        this.eventService.setLoading(false)

      }

      )
    }
  }

  onDelete(data) {
    Swal.fire({
      title: this.translateService.instant('FORM.Warning'),
      text: this.translateService.instant('FORM.WarningMessage'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#ff3d60',
      confirmButtonText: this.translateService.instant('FORM.WarningConfirm'),
      cancelButtonText: this.translateService.instant('FORM.Cancel')
    }).then(result => {
      if (result.value) {
        this.apiService.deleteItem('devices', data).subscribe(res =>
          Swal.fire(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessageDelete'), 'success')
            .then(
              res => {
                this.fetchData();
              }
            )
        )
      }
    });
  }



  makeForm(d?) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
      id: [data.id],
      model: [data.model, [Validators.required]],
      serial_number: [data.serial_number, [Validators.required]],
      version: [data.version, [Validators.required]],
      userId: [data.userId, [Validators.required]],
      idWs: [data.idWs, [Validators.required]],
      // currency: [data.currency || 'VND'],
    })
  }

  makePrices(data = []) {
    let arr = new FormArray([]);
    data.forEach(i => {

      arr.push(this.makePrice(i))
    })
    return arr;
  }

  makePrice(d?) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
      key: [data.key, [Validators.required]],
      // price: [data.price, [Validators.required, Validators.min(0)]],
      value: [data.value, [Validators.required]],
      // id: [data.id]
    })
  }

  uploadedImage

  saveData() {
    this.submitted = true
    if (this.formData.valid) {
      const value = this.formData.value
      value['status'] = value['status'] == true ? 1 : 0
      // value['prices'][0]['condition'] = 1
      // value['prices'][1]['condition'] = 2
      // value['price']= value['prices']
      this.eventService.setLoading(true)
      if (value.id) {
        this.apiService.editItem(this.tableName, value.id, value).subscribe({
          next: res => {
            this.submitted = false
            this.formData.reset()
            this.fetchData()
            this.modalService.dismissAll()
            this.apiService.showToast(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessage'), 'success')
            this.eventService.setLoading(false)
          },
          error: e => {
            this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('FORM.ErrorMessage'), 'error')
            this.eventService.setLoading(false)
          }
        })
      } else {
        this.apiService.addItem(this.tableName, value).subscribe({
          next: res => {
            this.fetchData()
            this.apiService.showToast(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessageAdd'), 'success')
            this.eventService.setLoading(false)
            this.modalService.dismissAll()
          },
          error: e => {
            this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('FORM.ErrorMessageAdd'), 'error')
            this.eventService.setLoading(false)
          }
        })
      }

    } else {
      console.log('invalid', this.formData)
      Object.values(this.formData.controls).forEach(control => {
        if (control.status == 'INVALID') {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  goToPreviousPage() {
    if (this.pageIndex > 1) {
      this.pageIndex--;
      this.fetchData();
    }
  }

  goToNextPage() {
    if (this.pageIndex < this.totalCount) {
      this.pageIndex++;
      this.fetchData();
    }
  }
}
