<!-- <app-pagetitle title="{{'PAGE.REPORT.BreadcrumbTitle' | translate}}"
    [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->

    <div class="card">
        <div class="card-body" style="min-height: 400px;">
            <div class="mb-0" *ngIf="data">
                <!-- <hr class="mb-2"> -->
                    <div class="row">
                        <div class="d-flex col col-md-12">
                            <h3 style="margin: 0px !important;" class="col-md-8 pt-1">{{'CHI TIẾT GIAO DỊCH THANH TOÁN' | translate}}</h3>
                            <!-- <div class="col-md-4 d-flex" style="gap: 5px;">
                                <button class="btn btn-dark">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer-fill" viewBox="0 0 16 16">
                                    <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1"/>
                                    <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"/>
                                  </svg>
                                  In
                                </button>
                                <button class="btn btn-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text-fill" viewBox="0 0 16 16">
                                        <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M5 4h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1m-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1m0 2h3a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1"/>
                                    </svg>
                                    Xuất hóa đơn
                                </button>
                                <button class="btn btn-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capslock-fill" viewBox="0 0 16 16">
                                        <path d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816L7.27 1.047zM4.5 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1z"/>
                                      </svg>
                                    Xuất file
                                </button>

                            </div> -->
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col col-md-12">
                            <p class="label_title col col-md-12">Thông tin tài khoản</p>                            
                            <div class="d-flex">
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second">Số tài khoản</p>
                                    <p class="col col-md-6 font-weight-bolder">{{data.account_number}}</p>
                                </div>
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second">Chủ tài khoản</p>
                                    <p class="col col-md-6 font-weight-bolder">{{data.bank_account_name}}</p>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second">Ngân hàng</p>
                                    <p class="col col-md-6 font-weight-bolder">{{data.bank_name}}</p>
                                </div>
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second"></p>
                                    <p class="col col-md-6"></p>
                                </div>
                        </div>            
                    <hr>
                    <div class="row">
                        <div class="col col-md-12">
                            <p class="label_title col col-md-12">Thông tin giao dịch</p>
                            <div class="d-flex">
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second color_title_second">Mã giao dịch</p>
                                    <p class="col col-md-6">{{data.code}}</p>
                                </div>
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second color_title_second">Số tiền</p>
                                    <p class="col col-md-6 font-weight-bolder">{{data.total | currency:'VND':'symbol':'1.0-0' }}</p>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second color_title_second">Ghi chú</p>
                                    <p class="col col-md-6">{{data.note}}</p>
                                </div>
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second">Trạng thái</p>
                                    <p class="col col-md-6 text-danger"  *ngIf="data.status == '0'">Hủy</p>
                                    <p class="col col-md-6 text-success"  *ngIf="data.status == '1'">Đã thanh toán</p>
                                    <p class="col col-md-6 text-warning"  *ngIf="data.status == '2'">Đang chờ</p>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second">Ngày giao dịch</p>
                                    <p class="col col-md-6">{{data.created_at}}</p>
                                </div>
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second"></p>
                                    <p class="col col-md-6"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col col-md-12">
                            <p class="label_title col col-md-12">Thông tin thiết bị</p>
                            <div class="d-flex">
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second">Số seri</p>
                                    <p class="col col-md-6">{{data.serial_number}}</p>
                                </div>
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second">Model</p>
                                    <p class="col col-md-6">{{data.model}}
                                    </p>
                                </div>
                                
                            </div>
                            <div class="d-flex">
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second">Phiên bản</p>
                                    <p class="col col-md-6">{{data.version}}</p>
                                </div>
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second"></p>
                                    <p class="col col-md-6"></p>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    </div>
                    <hr>
                    <!-- <div class="row">
                        <div class="col col-md-12">
                            <p class="label_title col col-md-12">Thông tin điểm thu</p>
                            <div class="d-flex">
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second">Mã - Tên đại lý</p>
                                    <p class="col col-md-6"></p>
                                </div>
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second">Mã - Tên tiền thu</p>
                                    <p class="col col-md-6"></p>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second">Mã trừ tiền/ Mã chuẩn chi</p>
                                    <p class="col col-md-6"></p>
                                </div>
                                <div class="col col-md-6 d-flex">
                                    <p class="col col-md-5 color_title_second">Mã đơn hàng</p>
                                    <p class="col col-md-6"></p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <hr>
            
                    
                    <!-- End search -->
                </div>

            </div>
            <div class="d-flex justify-content-end">
                <!--  -->
                <!-- <button class="btn btn-success mb-2" (click)="openModal(content)">
                  <i class="mdi mdi-plus mr-2"></i> Th4m dịch vụ
              </button> -->
            </div>
    
        </div>
    </div>
    
    <!-- <ng-template > -->
    
    <!-- </ng-template> -->
    

